@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "react-alice-carousel/lib/alice-carousel.css";

:root {
    --bg-primary-color: black;
    --bg-seconday-color: gray;

    --text-primary-color: white;
    --text-secondary-color: #b1b4bd;

    --small-padding: 1rem;
    --medium-padding: 2rem;
    --large-padding: 3rem;

    --ultralarge-padding: 6rem;

    --nav-height: 3rem;
    /*--pink-bg: rgb(251 113 133/1);*/
    --pink-bg: rgb(175, 113, 251);

    --max-width-screen: 1900px;
    --max-width-inner: 1200px;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

}

html {
    font-size: 16px;
}


.text-shadow {
    text-shadow: -1px 8px 12px rgba(14, 8, 14, .4);
}

.body-container {
    position: relative;
    overflow-x: hidden;
    margin: 0;
    line-height: inherit;
    height: 100%;
    min-height: 100vh;
    width: 100%;
    background-color: var(--bg-primary-color);
    color: var(--text-primary-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.background-container {
    position: fixed;
    inset: 0;
    pointer-events: none;
    overflow: hidden;
    height: 100vh
}

.background-holder {
    position: absolute;
    top: 0;
    height: 100vh;
    object-fit: cover;
}

.background-item {
    height: 100vh;
    object-fit: cover;
    overflow: hidden;
}

.background-item.fadeBG {
    -webkit-animation: invisible 1 ease 200ms forwards;
}

@keyframes slide {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes invisible {
    from {
        visibility: visible;
    }
    to {
        visibility: hidden;
    }
}

.navigation-container {
    width: 100%;
    z-index: 50;
    position: relative;
}

.navigation-container-mobile {
    position: fixed;
    width: 100%;
}

.navigation-holder-mobile {
    position: relative;
    z-index: 50;
    display: flex;
    height: 55px;
    align-items: center;
    backdrop-filter: blur(5px);
    /*background-color: rgba(0, 0, 0, 0.45);*/
    background-color: rgba(0, 0, 0, 0.72);
}

.navigation-holder-mobile:after {
    content: " ";
    height: .5px;
    width: 100vw;
    background: rgba(255, 255, 255, 0.24);
    position: absolute;
    bottom: -.5px;
}

.navigation-mobile-logo {
    fill: currentColor;
    height: 1.25rem;
    stroke: var(--bg-primary-color);
    color: var(--text-primary-color);
    padding-left: var(--small-padding);
}

.navigation-container-pc {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navigation-holder-pc {
    display: flex;
    height: 3rem;
    align-items: center;
    border-radius: 1.5rem;
}

.navigation-pc-logo {
    fill: currentColor;
    height: 3rem;
    stroke: var(--bg-primary-color);
    color: var(--text-primary-color)
}

.page-container {
    position: relative;
}

.page-holder {
    /*display: flex;*/
    /*flex-direction: column;*/
}

.hero-section-container {
    height: calc(100vh - var(--nav-height));
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--medium-padding);
    background: linear-gradient(180deg, transparent 0%, var(--bg-primary-color) 100%);
}


.particles-absolute {
    max-width: 25rem;
    position: absolute;
}

.particles-absolute.pa-1 {
    top: .3rem;
    left: 15.3rem;
}

.particles-absolute.pa-2 {
    top: 7.1rem;
    left: 23.3rem;
}

.particles-absolute.pa-3 {
    top: 7.1rem;
    right: 13.5rem;
}

.particles-absolute.pa-4 {
    bottom: 16.2rem;
    left: 16.8rem;
}

.particles-absolute.pa-5 {
    bottom: 19.6rem;
    right: 13.5rem;
}

.hero-heading-text {
    color: var(--text-primary-color);
    font-weight: 800;
    letter-spacing: .025rem;
    font-size: 3rem;
    line-height: 1.2;
    max-width: 42rem;
    text-align: center;
}

.hero-sub-heading-text {
    font-size: 1.125rem;
    line-height: 1.75rem;
    max-width: 36rem;
    text-align: center;
}

.hero-sub-heading-text b {
    color: var(--text-primary-color);
}

.section-heading-text {
    font-weight: 700;
    /*font-size: 3rem;*/
    /*line-height: 3.2rem;*/
    max-width: 30rem;
    /*background: red;*/
    font-size: 1.875rem;
    line-height: 2.45rem;
}

.default-heading-text {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2.2rem;
}


.child-center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.secondary-Text {
    color: var(--text-secondary-color)
}

.heading-center {
    text-align: center;
}

.section-sub-heading-text {
    font-size: 1rem;
    line-height: 1.5rem;
}

.description-text {
    font-size: .8rem;
    line-height: 1.5rem;
}

.our-service-container {
    position: relative;
    background: var(--bg-primary-color);
    display: flex;
    justify-content: center;
}

.max-inner-width-handler {
    max-width: var(--max-width-inner);
    width: 100%;
    padding-left: var(--small-padding);
    padding-right: var(--small-padding);
}

.contact-inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 450px;
}

.our-service-slot-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px
}

.pt-m {
    padding-top: var(--medium-padding);
}

.pt-l {
    padding-top: var(--large-padding);
}

.pt-ul {
    padding-top: var(--ultralarge-padding);
}

.our-service-slot-holder {
    display: grid;
    gap: 5rem;

    /*LG*/
    /*grid-template-columns: repeat(3, minmax(0, 1fr));*/
    /*MD*/
    /*grid-template-columns: repeat(2, minmax(0, 1fr));*/
    /*!*SM*!*/
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.kdx-detail-slot-container {
    margin-bottom: 1rem;
    display: flex;
    gap: 1.5rem;
    align-items: center;
    flex-direction: row;
}

.kdx-detail-slot-icon-container_l1 {
    position: relative;
    width: 3.5rem;
    height: 3.5rem;
    /*background: yellow;*/
}

.kdx-detail-slot-icon-container_l2 {
    position: absolute;
    left: 1.25rem;
    right: 1.25rem;
    height: .5rem;
    bottom: 0;
    background-color: var(--pink-bg);
    filter: blur(12px);
    opacity: .5;
}

.kdx-detail-slot-icon-holder {
    position: relative;
    overflow: hidden;
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 1rem;
    padding: 1px;
    background-image: linear-gradient(to bottom, #334155 0%, #171c23 100%);
}

.kdx-detail-slot-icon-holder_l1 {
    position: absolute;
    left: 1rem;
    right: 1rem;
    bottom: -1rem;
    height: 1rem;
    filter: blur(12px);
    background-color: var(--pink-bg);
}

.kdx-detail-slot-icon-holder_l2 {
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to bottom, #334155 -100%, #171c23 100%);
}

.kdx-detail-slot-icon-holder_l3 {
    position: absolute;
    left: 1rem;
    right: 1rem;
    height: 1rem;
    bottom: -.5rem;
    background: var(--pink-bg);
    filter: blur(10px) brightness(.75) drop-shadow(0 10px 8px rgba(0, 0, 0, .04)) drop-shadow(0 4px 3px rgba(0, 0, 0, .1));
}

.kdx-detail-slot-icon {
    fill: currentColor;
    height: 3rem;
    color: var(--pink-bg);
    transition-property: transform;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s;
}

.kdx-detail-heading {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    /*Lg*/
    font-size: 1.25rem;
    line-height: 1.75rem;
    /*Sm MD*/
    /*font-size: 1.125rem;*/
    /*line-height: 1.75rem;*/
}

.kdx-detail-sub-heading {
    /*line-height: 1.625;*/
    font-size: 1rem;
    line-height: 1.5rem;
    max-width: 28rem;
    color: var(--text-secondary-color);
}

.kdx-sub-heading {
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--text-secondary-color);
}

.tech-stack-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    background: var(--bg-primary-color);
}

@keyframes scroll {
    0% {
        transform: translateX(0)
    }

    to {
        transform: translateX(calc(-256px * 8))
    }
}

.tech-stack-holder {
    display: flex;
    /*animation: scroll 30s linear infinite;*/
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.tech-stack-item {
    flex-shrink: 0;
    position: relative;
    height: 6rem;
    width: 16rem;
}

.tech-stack-item-image {
    -o-object-position: center;
    object-position: center;
    -o-object-fit: contain;
    object-fit: contain;
}

.tech-stack-holder-shader {
    position: absolute;
    height: 9rem;
    bottom: 0;
    width: 10%;
}

.left-shader {
    background: linear-gradient(-90deg, transparent 0%, var(--bg-primary-color) 100%);
}

.right-shader {
    right: 0;
    background: linear-gradient(90deg, transparent 0%, var(--bg-primary-color) 100%);
}


.kdx-grid {
    grid-row-gap: 16px;
    grid-column-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
}


.container {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    padding: 3rem 1em;
}

.grid {
    width: 100%;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
}

.content-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
}

.trusted-item {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20vh;
    display: flex;
    max-width: 25rem;
}

.trusted-item.last {
    margin-bottom: 0;
}


.text-xl {
    font-size: 24px;
}

.trusted-item-header {
    align-items: center;
    display: flex;
}

.trusted-white-overlay {
    /*width: 49vw;*/
    flex: 1;
    height: 30vh;
    background: linear-gradient(0, transparent 0%, var(--bg-primary-color) 100%);
    /*background: linear-gradient(0, transparent 0%, red 100%);*/
    position: -webkit-sticky;
    z-index: 10;
    position: sticky;
    top: 53px;
    bottom: auto;
    left: 0;
    right: 0;
}

.trusted-white-overlay.bottom {
    height: 20vh;
    background: linear-gradient(180deg, transparent 0%, var(--bg-primary-color) 100%);
    top: 80vh;
}

.trusted-overlay-container {
    height: 100%;
    position: absolute;
    top: 0;
    bottom: auto;
    left: 0;
    right: 0;
}

.trusted-content {
    padding-top: 5rem;
    padding-bottom: 5rem;
    position: relative;
}

.trusted-content.trusted-list {
    padding-top: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-small {
    font-size: 60%;
}


.development-cycle-header, .development-cycle-list {
    grid-area: span 1 / span 3 / span 1 / span 3;
}

.faq-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--bg-primary-color);
}

.faq-holder {
    display: flex;
    flex-direction: column;
    gap: var(--medium-padding);
    align-items: center;
}

.curve-absolute {
    width: 100%;
    position: absolute;
    top: -12rem;
    left: 0;
    right: 0;
}

.shape-absolute {
    width: 100%;
    height: 8rem;
    background-color: #0e080e;
    background-image: linear-gradient(#0e080e, rgba(14, 8, 14, 0));
    position: absolute;
    top: -7rem;
}


.faq-item {
    width: 100%;
    cursor: pointer;
    max-width: 50rem;
    background-color: rgba(255, 255, 255, .16);
    border-radius: 1.5rem;
    overflow: hidden;
    box-shadow: 0 0 12px rgba(0, 0, 0, .25);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.faq-question {
    width: 100%;
    color: var(--text-primary-color);
    justify-content: space-between;
    white-space: break-spaces;
    display: flex;
}

.toggle-text {
    z-index: 2;
    font-size: 1.8rem;
    line-height: 1.3;
    position: relative;
}

.faq-icon {
    z-index: 2;
    width: 24px;
    filter: invert();
    margin-right: 16px;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: auto;
    right: 0%;
}

.faq-answer {
    display: none;
    position: relative;
    height: 0;
    overflow: hidden;
}

.faq-answer.open {
    display: block;
    height: auto;
}

.faq-body-cont {
    width: 80%;
    border-top: 1px #000;
    border-radius: 0;
    padding: 0 30px;
}


.faq-component {
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    display: flex;
}

.faq-wrapper {
    text-align: center;
    padding-bottom: 4rem;
}


.contact-outer-container {
    position: relative;
    width: 100%;
    background: var(--bg-primary-color);
    display: flex;
    justify-content: center;
}

.contact-container {
    width: 400px;
}

.steps {
    margin-bottom: 10px;
    position: relative;
    height: 25px;
}

.steps > div {
    position: absolute;
    top: 0;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    height: 25px;
    padding: 0 5px;;
    display: inline-block;
    width: 80px;
    text-align: center;
    -webkit-transition: .3s all ease;
    transition: .3s all ease;
}

.steps > div > span {
    line-height: 25px;
    height: 25px;
    margin: 0;
    color: #777;
    font-family: 'Roboto', sans-serif;
    font-size: .9rem;
    font-weight: 300;
}

.steps > div > .liner {
    position: absolute;
    height: 2px;
    width: 0%;
    left: 0;
    top: 50%;
    margin-top: -1px;
    background: #999;
    -webkit-transition: .3s all ease;
    transition: .3s all ease;
}

.step-one {
    left: 0;
}

.step-two {
    left: 50%;
    clip: rect(0, 0px, 25px, 0px);
}

.step-three {
    left: 100%;
    clip: rect(0, 0px, 25px, 0px);
}

.line {
    width: 100%;
    height: 5px;
    background: #ddd;
    position: relative;
    border-radius: 10px;
    overflow: visible;
    margin-bottom: 50px;
}

.line .dot-move {
    position: absolute;
    top: 50%;
    left: 0%;
    width: 15px;
    height: 15px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: #ddd;
    border-radius: 50%;
    -webkit-transition: .3s all ease;
    transition: .3s all ease;
}

.line .dot {
    position: absolute;
    top: 50%;
    width: 15px;
    height: 15px;
    left: 0;
    background: #ddd;
    border-radius: 50%;
    -webkit-transition: .3s all ease;
    transition: .3s all ease;
    -webkit-transform: translate(-50%, -50%) scale(.5);
    -ms-transform: translate(-50%, -50%) scale(.5);
    transform: translate(-50%, -50%) scale(.5);
}

.line .dot.zero {
    left: 0%;
    background: #bbb;
}

.contact-container.slider-one-active .dot.zero {
    background: #5892fc;
}

.line .dot.center {
    left: 50%;
    background: #bbb
}

.line .dot.full {
    left: 100%;
    background: #bbb
}

.slider-ctr {
    width: 100%;
    overflow: hidden;
}

.slider {
    overflow: hidden;
    width: 1200px;
    -webkit-transition: .3s all ease;
    transition: .3s all ease;
    -webkit-transform: translate(0px) scale(1);
    -ms-transform: translate(0px) scale(1);
    transform: translate(0px) scale(1);
}

.contact-container.slider-one-active .slider-two,
.contact-container.slider-one-active .slider-three {
    -webkit-transform: scale(.5);
    -ms-transform: scale(.5);
    transform: scale(.5);
}

.contact-container.slider-two-active .slider-one,
.contact-container.slider-two-active .slider-three {
    -webkit-transform: scale(.5);
    -ms-transform: scale(.5);
    transform: scale(.5);
}

.contact-container.slider-three-active .slider-one,
.contact-container.slider-three-active .slider-two {
    -webkit-transform: scale(.5);
    -ms-transform: scale(.5);
    transform: scale(.5);
}

.slider-one,
.slider-two,
.slider-three {
    -webkit-transition: .3s all ease;
    transition: .3s all ease;
}

.slider-form {
    float: left;
    width: 400px;
    text-align: center;
}

.slider-form h2 {
    font-size: 1.5rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    margin-bottom: 50px;
    color: #999;
    position: relative;
}

.slider-form h2 .yourname {
    font-weight: 400;
}

.slider-form h3 {
    font-size: 1.5rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    margin-bottom: 50px;
    line-height: 1.5;
    color: #999;
    position: relative;
}

.slider-form h3 .balapa {
    font-family: 'Pacifico', sans-serif;
    display: inline-block;
    color: #5892fc;
    text-decoration: none
}

.slider-form [type="text"] {
    width: 100%;
    box-sizing: border-box;
    padding: 15px 20px;
    background: #fafafa;
    border: 1px solid transparent;
    color: #777;
    border-radius: 50px;
    margin-bottom: 50px;
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    position: relative;
    z-index: 99;
}

.slider-form [type="text"]:focus {
    background: #fcfcfc;
    border: 1px solid #ddd;
}

.slider-form button,
.reset {
    display: inline-block;
    text-decoration: none;
    background: #5892fc;
    border: none;
    color: white;
    padding: 10px 25px;
    font-size: 1rem;
    border-radius: 3px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    position: relative;
}

/*  emot */

.label-ctr {
    margin-bottom: 50px;
}

label.radio {
    height: 55px;
    width: 55px;
    display: inline-block;
    margin: 0 10px;
    background: transparent;
    position: relative;
    border-radius: 50%;
    cursor: pointer
}

label.radio input {
    visibility: hidden
}

label.radio input:checked + .emot {
    -webkit-transform: scale(1.25);
    -ms-transform: scale(1.25);
    transform: scale(1.25);
}

label.radio input:checked + .emot,
label.radio input:checked + .emot .mouth {
    border-color: #5892fc;
}

label.radio input:checked + .emot:before,
label.radio input:checked + .emot:after {
    background: #5892fc;
}

label.radio .emot {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    border-radius: 50%;
    border: 2px solid #ddd;
    -webkit-transition: .3s all ease;
    transition: .3s all ease;
}

label.radio .emot:before {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    width: 5px;
    height: 10px;
    background: #ddd;
}

label.radio .emot:after {
    content: "";
    position: absolute;
    top: 15px;
    right: 15px;
    width: 5px;
    height: 10px;
    background: #ddd;
}

label.radio .emot .mouth {
    position: absolute;
    bottom: 10px;
    right: 15px;
    left: 15px;
    height: 15px;
    border-radius: 50%;
    border: 3px solid #ddd;
    background: transparent;
    clip: rect(0, 35px, 10px, 0);
}

label.radio .emot .mouth.smile {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

label.radio .emot .mouth.sad {
    -webkit-transform: translateY(50%);
    -ms-transform: translateY(50%);
    transform: translateY(50%);
}

/*	center */

.contact-container.center .line .dot-move {
    left: 50%;
    -webkit-animation: .3s anim 1;
}

.contact-container.center .line .dot.center {
    background: #5892fc;
}

.contact-container.center .slider {
    -webkit-transform: translate(-400px);
    -ms-transform: translate(-400px);
    transform: translate(-400px);
}

.contact-container.center .step-two {
    clip: rect(0, 100px, 25px, 0px);
}

.contact-container.center .step-one .liner {
    width: 100%;
}

/*	full */

.contact-container.full .line .dot-move {
    left: 100%;
    -webkit-animation: .3s anim 1;
}

.contact-container.full .line .dot.full {
    background: #5892fc;
}

.contact-container.full .slider {
    -webkit-transform: translate(-800px);
    -ms-transform: translate(-800px);
    transform: translate(-800px);
}

.contact-container.full .step-two,
.contact-container.full .step-three {
    clip: rect(0, 100px, 25px, 0px);
}

.contact-container.full .step-one .liner,
.contact-container.full .step-two .liner {
    width: 100%;
}

.footer-container {
    background: var(--bg-primary-color);
}

.footer-logo-container {
    position: relative;
    z-index: 10;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -1.4rem;
    width: 100%;
}

.footer-logo-masker {
    position: absolute;
    height: 2rem;
    left: 2.35rem;
    width: 1rem;
    background: var(--bg-primary-color);
    /*background: red;*/
    filter: blur(5px);
    /*border-radius: 100%;*/
}

.footer-logo {
    position: relative;
    height: 2rem;
    left: 2rem;
    fill: currentColor;
    stroke: var(--bg-primary-color);
    color: var(--text-primary-color)
}

.footer-holder {
    position: relative;
    border-top: 1px solid white;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 2rem 1.5rem;
    flex-direction: column;
    gap: var(--small-padding);
}

.footer-holder_1 {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    display: flex;
    width: 100%;
    align-items: stretch;
    justify-content: space-between;
}

.footer-holder_c1 {
    margin-top: 3rem;
    /*LG*/
    /*order: 2;*/
    /*SM*/
    /*order: 1;*/
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer-holder_c2 {
    order: 1;
    display: flex;
}

.footer-holder_c2_c1 {
    margin-bottom: 1.5rem;
    font-size: .75rem;
    line-height: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .05em;
}

@keyframes color {
    from {
        background-position: 0% 50%;
    }
    to {
        background-position: 100% 50%;
    }
}

.gold-text-anim {
    color: transparent;
    background: rgb(255, 0, 0);
    /*background: linear-gradient(*/
    /*        to right, #a2682a 0%, #be8c3c 8%, #be8c3c 18%, #d3b15f 27%, #faf0a0 35%, #ffffc2 40%, #faf0a0 50%,*/
    /*                  #d3b15f 58%, #be8c3c 67%, #b17b32 77%, #bb8332 83%, #d4a245 88%, #e1b453 93%, #a4692a 100%*/
    /*);*/
    /*background: linear-gradient(120deg, rgba(186, 148, 62, 1) 0%, rgba(236, 172, 32, 1) 20%, rgba(186, 148, 62, 1) 39%, rgba(249, 244, 180, 1) 50%, rgba(186, 148, 62, 1) 60%, rgba(236, 172, 32, 1) 80%, rgba(186, 148, 62, 1) 100%);*/
    background: linear-gradient(45deg, rgb(175, 113, 251), rgb(251, 113, 133), rgb(175, 113, 251));
    background-clip: text;
    -webkit-background-clip: text;
    background-size: 500%;
    animation: color 4s linear infinite;
}

/*div {*/
/*    background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),*/
/*    radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);*/
/*}*/

.development-cycle-container {
    background: var(--bg-primary-color);
    display: flex;
    justify-content: center;
}

.our-service-slot-holder {
    /*display: grid;*/
    /*gap: 5rem;*/

    /*LG*/
    /*grid-template-columns: repeat(3, minmax(0, 1fr));*/
    /*MD*/
    /*grid-template-columns: repeat(2, minmax(0, 1fr));*/
    /*!*SM*!*/
    /*grid-template-columns: repeat(1, minmax(0, 1fr));*/
}

@media screen and (max-width: 767px) {
    .container {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .grid {
        grid-template-columns: 1fr 1fr;
    }

    .trusted-item {
        margin-bottom: 3em;
    }

    .trusted-overlay-container {
        display: none;
    }

    .trusted-content {
        padding-top: 0;
        padding-bottom: 3rem;
    }

    .trusted-content.trusted-list {
        padding-top: 0;
    }

}

/* Below 425px Mobile */
/* 425px - 768px Tablet */
@media (min-width: 700px) {
    /* Code Here For Tablet Screen  */
    .our-service-slot-holder {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .max-inner-width-handler {
        padding-left: var(--large-padding);
        padding-right: var(--large-padding);
    }

    .footer-holder {
        justify-content: space-between;
        flex-direction: row;
    }

    .footer-logo-masker {
        left: 5.35rem;
    }

    .footer-logo {
        left: 5rem;
    }

    .footer-holder {
        padding: 1.5rem 5rem 1.5rem;
    }

}

/* Above 768px Desktop */
@media (min-width: 900px) {
    /* Code Here For Desktop Screen  */
    .our-service-slot-holder {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .max-inner-width-handler {
        padding-left: var(--small-padding);
        padding-right: var(--small-padding);
    }
}

.faq-arrow-container {
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 200ms ease-in-out;
}

.faq-arrow-container.open {
    transform: rotate(-180deg);
}

.contact-button {
    padding: 4px 12px;
    cursor: pointer;
    background: var(--text-primary-color);
    /*color: var(--bg-primary-color);*/
    border-radius: 25px;
    font-weight: 600;
    text-decoration: none;


    color: rgb(0.9059, 0.7922, 0.4235);
}


.list-container {
    display: flex;
    justify-content: center;
    padding-top: 50px;
}

.list-holder {
    max-width: 80%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.list-heading {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.list-values {
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--text-secondary-color);
}

.list-values a {
    text-decoration: none;
    color: white;
}

.listing-header {
    display: flex;
    align-items: center;
    padding-top: 50px;
    flex-direction: column;
}

.footer-contact-holder {
    display: flex;
    gap: 5px;
}

.footer-contact-holder * {
    text-decoration: none;
}

.contact-holder {
    display: flex;
    flex-direction: column;
    gap: var(--small-padding);
    align-items: center;
}

.contact-button-holder {
    width: fit-content;
}

.a-no-decoration {
    color: var(--text-primary-color);
    text-decoration: none;
}